export default {
    //错题进度
    'wrongProgress': {
        url: '/town/note/statistics/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //错题列表
    'wrongList': {
        url: '/town/note/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
    //错题上传
    'uploadWrongCourse': {
        url: '/town/note/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 目录页总数据
    'getAllStatistics': {
        url: '/town/note/all/statistics/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获取相似题
    'getSimilarList': {
        url: '/town/note/similar/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获取相似题new
    'getAiSimilarList': {
        url: '/town/note/similar/list/{user_code}',
        serve: 'social',
        login: false,
    },
    // ai相似题答题
    'postSimilarAnswer': {
        url: '/town/note/similar/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 目录页总数据new
    'getStatistics': {
        url: '/town/note/all/statistics/user/v2/{user_code}',
        serve: 'social',
        login: false,
    },
    // 分页查询错题列表
    'getWrongList': {
        url: '/town/note/user/page/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获取待订正习题
    'getMistakesList': {
        url: '/town/note/group/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获得我的战绩和喜报
    'getRecord': {
        url: '/town/note/similar/record/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 获得喜报
    'getGoodnews': {
        url: '/town/note/group/report/user/{user_code}',
        serve: 'social',
        login: false,
    },
    // 订正喜报入口查看错题本
    'getMistakesRecord': {
        url: '/town/note/group/record/list/user/{user_code}',
        serve: 'social',
        login: false,
    },
}