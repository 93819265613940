<template>
    <div class="week-summary-index" :class="{ 'week-overlay-index': !loading && show ? true : false }">
        <van-dialog v-model="show" @close="close" :closeOnClickOverlay="false" :show-cancel-button="false"
            :show-confirm-button="false">
            <div class="week-summary-loading" v-if="loading">
                <div class="week-animation-container">
                    <img :src="`${PARAMS.assetsUrlMiddle}/img/week/week_an.gif`" alt="加载中">
                </div>
            </div>
            <div class="week-summary-container" v-else>
                <div class="week_1"></div>
                <div class="week_2"></div>
                <div class="week_3">
                    <div class="week_content mini-scrollbar-week">
                        <div class="con_1" v-if="top1.length > 0">
                            <span>本周的学习集中在</span>
                            <span class="con_1_text" v-for="(item, index) in top1" :key="index">
                                <span class="con_1_b">{{ item }}</span>
                                <span v-if="index === top1.length - 2">和</span>
                                <span v-else-if="top1.length > 1 && index < top1.length - 2">、</span>
                            </span>
                        </div>
                        <div class="con_2" v-for="item in top2" :key="item.id">
                            <span>{{ item.homeworkType === 4 ? '知识海岛' : '练功房' }}</span>
                            <span class="con_2_b1">{{ item.homeworkName }}</span>
                            <span>{{ item.homeworkType === 4 ? '正确率偏低，建议观看切片视频重复练习' : '正确率偏低，建议重新练习' }}</span>
                            <div class="con_2_go scale-button" @click="goPlay(item)">
                                <span class="go_text">{{ item.homeworkType === 4 ? '去练习' : '去练习' }}</span>
                            </div>
                        </div>
                        <div class="week_content_separate" v-if="correctionList.length > 0">
                            <div class="week_note"></div>
                            <div class="week_line"></div>
                        </div>
                        <div class="con_2" v-for="item in correctionList" :key="item.suggestReviewId">
                            <span>{{ item.homeworkName }}有</span>
                            <span class="con_2_b2">{{ item.correctCount }}</span>
                            <span>道习题待订正</span>
                            <div class="con_2_go scale-button" @click="goPlay(item)">
                                <span class="go_text">去订正</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="week_header">
                    <div class="week_header_con" v-html="title"></div>
                </div>
                <div class="week_4"></div>
                <div class="week_5">
                    <div class="week_5_1"></div>
                </div>
                <div class="week_5_icon">
                    <div class="week_5_2"></div>
                    <div class="week_5_3"></div>
                </div>
                <div class="week_6" @click="close"></div>
                <div class="week_7"></div>
                <div class="week_8"></div>
                <div class="week_9"></div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import taskApiClass from '@/API/page/taskcenter'
export default {
    name: '',
    props: {
    },
    data() {
        return {
            api: new taskApiClass(),
            appData: new this.appDataClass({ key: 'taskCenter' }),
            show: false,
            loading: false,
            timer: null,
            routerNameObj: {
                '3': 'task-subject-section', // 练功房
                '4': 'middle-palace-island', // 知识海岛
            },
            weekData: {},
        };
    },
    methods: {
        async open() {
            this.weekData = {}
            this.show = true
            this.loading = true
            clearTimeout(this.timer)
            const startTime = new Date()
            await this.getWeekData()
            const endTime = new Date()
            const time = endTime - startTime
            if (time < 700) {
                this.timer = setTimeout(() => {
                    this.loading = false
                }, 700 - time)
            } else {
                this.loading = false
            }
        },
        close() {
            this.show = false
            this.loading = false
            clearTimeout(this.timer)
            this.timer = null
        },
        async goPlay(v) {
            let { disabled, homeworkType } = v || {}
            if (disabled) return
            let fun = this.getRouteName(homeworkType)
            if (!fun) return
            this.close()
            let params = await this.getParams(v)
            this.appData.setStoreItem({ 'taskCenterOpenRoute': this.$route.fullPath })
            this._routeHand.goto({
                params,
                name: fun,
                type: 'push',
            })
        },
        getRouteName(homeworkType) {
            homeworkType = homeworkType || ''
            let { routerNameObj } = this
            return routerNameObj[homeworkType] || ''
        },
        async getParams(val) {
            let { homeworkType, homeworkId, difficulty } = val || {}
            let params = {}
            if (homeworkType == 3) {
                let { sectionName, courseId } = await this.getSubjectParent(homeworkId) || {}
                params = {
                    courseId, // 课程id
                    sectionName, // 章节名
                    id: homeworkId, // 章节id
                    difficulty, // 难度
                }
            }
            if (homeworkType == 4) {
                let { gradeId, bigIslandId, smallIslandId } = await this.getPalaceParams(homeworkId) || {}
                params = {
                    headId: gradeId, // 年级目录Id
                    currentLargePalaceId: bigIslandId, // 大岛Id
                    extendId: smallIslandId, // 小岛Id
                }
            }
            params = {
                routeData: this._common.encodeData(params)
            }
            return params
        },
        async getSubjectParent(section_id) {
            if (!section_id) return
            let res = await this.api.getSubjectParent({ section_id })
            return res
        },
        async getPalaceParams(palace_id) {
            if (!palace_id) return
            let res = await this.api.getPalaceParent({ palace_id })
            return res
        },
        async getWeekData() {
            const res = await this.api.getWeekList({ noLoad: true })
            if (!res) {
                this.weekData = {}
                return false
            }
            this.weekData = res
            return res
        }
    },
    created() {
    },
    components: {
    },
    computed: {
        title() {
            const { weekOnlineMinute = 'xx', weekLessonMinute = 'xx', weekAnswerCount = 'xx', weekUserRankNo = 'xx' } = this.weekData || {}
            return `本周共学习<span class='title-b'>${weekOnlineMinute}</span>分钟。听<span class='title-b'>${weekLessonMinute}</span>分钟的切片视频讲解并完成<span class='title-b'>${weekAnswerCount}</span>道练习题， 在所有初中同学中排第<span class='title-b'>${weekUserRankNo}</span>名。`
        },
        top1() {
            const { weekMaxSubjectName, weekMaxPalaceName } = this.weekData || {}
            let arr = []
            if (weekMaxSubjectName) arr.push(weekMaxSubjectName)
            if (weekMaxPalaceName) arr.push(weekMaxPalaceName)
            return arr
        },
        top2() {
            const { suggestReviewName, suggestReviewId, suggestReviewType, suggestDifficulty } = this.weekData || {}
            const arr = []
            if (suggestReviewId == null) return []
            arr.push({
                homeworkName: suggestReviewName,
                homeworkType: suggestReviewType,
                homeworkId: suggestReviewId,
                difficulty: suggestDifficulty || 0
            })
            return arr
        },
        correctionList() {
            const { correctionList = [] } = this.weekData || {}
            const res = correctionList.map(v => {
                return {
                    ...v,
                    homeworkName: v.suggestReviewName,
                    homeworkType: v.suggestReviewType,
                    homeworkId: v.suggestReviewId,
                    correctCount: v.suggestInCorrectCount || 0,
                    difficulty: v.suggestDifficulty || 0
                }
            })
            return res || []
        }
    },
    watch: {
    },
    mounted() {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less'>
.week-summary-index {
    .van-overlay {
        background-color: transparent;
    }

    &.week-overlay-index {
        >.van-overlay {
            background-color: rgba(0, 0, 0, .4);
        }
    }

    .van-dialog {
        background-color: transparent;
        width: 477px;
        height: 285px;
        overflow: visible;
        top: 55%;
        left: 50%;
        transform: translateY(-50%, -50%);

        .van-dialog__content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .week-summary-loading {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >.week-animation-container {
            position: relative;
            width: 200px;
            height: 160px;

            >img {
                width: 200px;
                height: 160px;
            }
        }
    }

    .week-summary-container {
        width: 477px;
        height: 285px;
        position: relative;
        .background-img-max(url('@{assetsUrlMiddle}/img/week/w1.png'));

        >.week_1 {
            position: absolute;
            left: -20px;
            bottom: -22px;
            width: 528px;
            height: 349px;
            z-index: -1;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w1_s.png'));
        }

        >.week_2 {
            position: absolute;
            left: 17px;
            top: 13px;
            width: 448px;
            height: 257px;
            z-index: 5;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w2.png'));
        }

        >.week_header {
            position: absolute;
            left: 0;
            top: 32px;
            height: 37px;
            width: 100%;
            padding: 0 37px;
            z-index: 6;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >.week_header_con {
                text-align: left;
                line-height: 12px;
                font-size: 9px;
                font-weight: bold;
                color: #8D6C53;
                word-break: break-all;
                word-wrap: break-word;

                >span {
                    word-break: break-all;
                    word-wrap: break-word;
                    font-size: 9px;
                    font-weight: bold;
                    color: #8D6C53;
                }

                >.title-b {
                    font-size: 9px;
                    font-weight: bold;
                    color: #F94A1A;
                }
            }


        }

        >.week_3 {
            position: absolute;
            left: 40px;
            top: 69px;
            width: 409px;
            height: 176px;
            z-index: 10;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w3.png'));
            padding: 12px;

            >.week_content {
                width: 100%;
                height: 100%;
                padding-right: 10px;

                >.week_content_separate {
                    width: 100%;
                    height: 33px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >.week_note {
                        width: 33px;
                        height: 33px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/week/note.png'));
                        margin-right: 7px;
                    }

                    >.week_line {
                        width: 370px;
                        height: 3px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/week/line.png'));
                    }
                }

                >.con_1 {
                    text-align: left;
                    line-height: 12px;
                    word-break: break-all;
                    word-wrap: break-word;
                    font-size: 9px;
                    font-weight: bold;
                    color: #8D6C53;
                    margin-bottom: 10px;

                    span {
                        font-size: 9px;
                        font-weight: bold;
                        color: #8D6C53;
                    }

                    >.con_1_text {

                        >.con_1_b {
                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                left: 0;
                                width: 100%;
                                // height: 2px;
                                border-bottom: 2px solid #FBBE79;
                                // background-color: #FBBE79;
                            }
                        }
                    }
                }

                >.con_2 {
                    margin: 5px 0;
                    line-height: 21px;
                    text-align: left;
                    white-space: pre-wrap;
                    word-break: break-all;
                    word-wrap: break-word;

                    >* {
                        display: inline;
                        vertical-align: middle;
                    }

                    >span {
                        display: inline;
                        line-height: 21px;
                        font-size: 9px;
                        font-weight: bold;
                        color: #8D6C53;
                        word-break: break-all;
                        word-wrap: break-word;
                    }

                    >.con_2_b1 {
                        color: #3DA1E9;
                    }

                    >.con_2_b2 {
                        color: #C63F3F;
                    }

                    >.con_2_go {
                        position: relative;
                        width: 53px;
                        height: 21px;
                        display: inline-block;
                        margin-left: 3px;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 2px;
                            width: 100%;
                            height: 100%;
                            .background-img-max(url('@{assetsUrlMiddle}/img/week/btn_1.png'));
                        }

                        >.go_text {
                            font-size: 9px;
                            font-weight: bold;
                            color: #ffffff;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            line-height: 9px;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                &.mini-scrollbar-week {

                    &::-webkit-scrollbar {
                        width: 9px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 2px;
                        background: linear-gradient(to right, #f8cc4b, #fbe778, #fbe26b);
                    }

                    &::-webkit-scrollbar-thumb::before {
                        content: '';
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 2px;
                        background-image: linear-gradient(to right, #f3b24c, #f9b954, #f7943b);
                    }

                    & {
                        overflow-y: auto;
                        overflow-x: hidden;
                    }
                }
            }
        }

        >.week_4 {
            position: absolute;
            left: -8px;
            top: 101px;
            width: 39px;
            height: 94px;
            z-index: 6;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w4.png'));
        }

        >.week_5 {
            position: absolute;
            left: 38px;
            top: -14px;
            width: 155px;
            height: 46px;
            z-index: 6;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w5.png'));

            >.week_5_1 {
                position: absolute;
                left: 32px;
                top: 9px;
                width: 95px;
                height: 16px;
                z-index: -1;
                .background-img-max(url('@{assetsUrlMiddle}/img/week/w5_1.png'));
            }
        }

        >.week_5_icon {
            position: absolute;
            left: 38px;
            top: -14px;
            width: 155px;
            height: 46px;
            z-index: 5;

            >.week_5_2 {
                position: absolute;
                left: 26px;
                top: -33px;
                width: 68px;
                height: 65px;
                z-index: -1;
                .background-img-max(url('@{assetsUrlMiddle}/img/week/w5_2.png'));
            }

            >.week_5_3 {
                position: absolute;
                left: 87px;
                top: -18px;
                width: 42px;
                height: 28px;
                z-index: -1;
                .background-img-max(url('@{assetsUrlMiddle}/img/week/w5_3.png'));
            }
        }

        >.week_6 {
            position: absolute;
            right: -26px;
            top: 7px;
            width: 49px;
            height: 37px;
            z-index: 3;
            cursor: pointer;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w6.png'));
        }

        >.week_7 {
            position: absolute;
            left: -2px;
            bottom: 22px;
            width: 32px;
            height: 48px;
            z-index: 3;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w7.png'));
        }

        >.week_8 {
            position: absolute;
            right: 59px;
            bottom: -12px;
            width: 47px;
            height: 31px;
            z-index: 3;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w8.png'));
        }

        >.week_9 {
            position: absolute;
            right: 92px;
            top: -5px;
            width: 95px;
            height: 53px;
            z-index: 3;
            .background-img-max(url('@{assetsUrlMiddle}/img/week/w9.png'));
        }
    }

}
</style>