
export default {
    'mind-palace-action': {
        t1: '查看结果',
        t2: '提示',
        t3: '成为正式课学员即可解锁海岛内容哦～',
        t4: '暂未上线 敬请期待',
        t5: '该板块只对购买过该学季课程的学员开放哦',
        t6: '请先观看视频才能开始练习哦',
    }
}