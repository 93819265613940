<template>
    <div class="special-task-list mini-scrollbar-task" ref="scrollContainerRef" v-show="show">
        <div class="special-task-wrapper" ref="innerRef">
            <div class="task-row scale-button" @click="goPlay(item)" v-for="(item, index) in taskLists" :key="index">
                <div class="task-row-left">
                    <div class="left-title">
                        <div class="task-index flex-center">
                            <div class="task-index-icon" :class="{ finish: item.competeness == 100 }"></div>
                            <span>任务{{ index + 1 }}</span>
                        </div>
                        <div class="task-des">
                            <span>{{ getTitle(item) }}</span>
                        </div>
                    </div>
                    <div class="left-content">{{ item.homeworkName }}</div>
                </div>
                <div class="task-row-right">
                    <div class="task-progress">
                        <span class="task-progress-text" :class="{ undo: item.competeness == 0 ? true : false }">{{
                            item.competeness + '%'
                            }}</span>
                        <div class="task-progress-bar-s"></div>
                        <div class="task-progress-bar">
                            <div class="task-progress-bar-inner"
                                :style="{ transform: `translateX(${item.competeness + '%'})` }"></div>
                        </div>
                    </div>
                    <div class="task-reward flex-center">
                        <span class="reward-text" v-if="item.competeness == 100">{{ '思维币已发放'
                            }}</span>
                        <div class="reward-icon" v-else>
                            <span>{{ '奖励' }}</span>
                            <span>{{ item.awardCount }}</span>
                            <div class="task-reward-icon"></div>
                        </div>
                    </div>
                    <div class="wait-handle scale-button" v-if="item.isRevise"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import taskApiClass from '@/API/page/taskcenter'
export default {
    name: '',
    props: {
        mode: {
            type: String,
            default: 'new'
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            taskApi: new taskApiClass(),
            taskLists: [],
            stopBottom: false,
            loading: false,
            isLoading: false,
            page: 0,
            size: 5,
            //是否已经获取过数据
            init: false
        };
    },
    methods: {
        // 获得任务列表
        async getTaskList() {
            if (this.isLoading) return;
            this.isLoading = true;
            const res = await this.taskApi.taskListV2({ is_new: this.mode === 'new' ? true : false, page: this.page, size: this.size });
            if (!res) {
                this.taskLists = []
                this.total = 0
                this.isLoading = false;
                return false
            }
            this.page += 1
            const { content, total } = res || {}
            if (content.length === 0) {
                this.stopBottom = true
                this.isLoading = false;
                return true
            }
            this.total = total
            if (this.page == 1) {
                this.taskLists = content
            } else {
                this.taskLists = this.taskLists.concat(content)
            }
            this.isLoading = false;
            return true
        },
        // 滚动条滚动事件
        async handleScroll() {
            if (this.loading) return
            if (this.stopBottom) return;
            // 内容高度
            const content = this.$refs.innerRef.offsetHeight;
            // 清空数据时，内容会变空，也会被动触发滚动，取消这种置置空影响
            if (content === 0) return;
            this.loading = true
            // 容器高度
            let container = this.$refs.scrollContainerRef.offsetHeight;
            // 容器卷起的大小
            const index = this.$refs.scrollContainerRef.scrollTop;
            // 容器的高度
            if (content - index <= container + 2 && !this.stopBottom) {
                // 告知触发底部事件后，这里重新让其距离底部一定距离，方便下次继续下滑（在数据没有满的时候，满了会自动补充数据，下面就有地方了，不需要刻意留
                this.$refs.scrollContainerRef.scrollTop = index - 2 < 0 ? 0 : index - 2;
                // 执行到底事件
                await this.getTaskList()
            }
            this.loading = false
        },
        scrollTo() {
            this.$refs.scrollContainerRef.scrollTop = val;
        },
        // 获得任务标题
        getTitle(val) {
            const { homeworkType, videoCount, exerciseCount, recommendTime } = val || {}
            if (homeworkType == 3) {
                return `${exerciseCount}道练习，建议${recommendTime}分钟内完成`
            }
            if (homeworkType == 4) {
                return `${videoCount}个切片视频，${exerciseCount}道练习，建议${recommendTime}分钟内完成`
            }
            return ''
        },
        // 点击任务进入
        goPlay(v) {
            this.$emit('goPlay', v)
        },
    },
    created() {
    },
    components: {
    },
    computed: {
    },
    watch: {
        // mode: {
        //     handler(val) {
        //         this.getTaskList()
        //     },
        //     deep: true,
        //     immediate: true
        // },
        show: {
            handler(val) {
                if (this.show && !this.init) {
                    this.init = true
                    this.getTaskList()
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$refs.scrollContainerRef.addEventListener("scroll", this.handleScroll);
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() {
        this.$refs.scrollContainerRef.removeEventListener("scroll", this.handleScroll);
    }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.special-task-list {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 7px;
    overflow: auto;

    >.special-task-wrapper {
        width: 100%;
        height: auto;

        >.task-row {
            padding: 9px 0 13px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #F8E9C2;

            &:last-child {
                border-bottom: none;
            }

            >.task-row-left {
                width: 0;
                flex: 1;
                margin-right: 10px;

                >.left-title {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 6px;

                    >.task-index {
                        flex-shrink: 0;
                        font-size: 10px;
                        line-height: 1em;
                        color: #8C6C52;
                        margin-right: 10px;

                        >.task-index-icon {
                            width: 11px;
                            height: 11px;
                            margin-right: 4px;
                            .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/circle_u.png'));

                            &.finish {
                                .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/circle_f.png'));
                            }
                        }

                        >span {
                            font-size: 10px;
                            line-height: 1em;
                            color: #8C6C52;
                            font-weight: bold;
                        }
                    }

                    >.task-des {
                        flex: 1;
                        flex-shrink: 0;
                        margin-right: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: start;

                        >span {
                            font-size: 9px;
                            color: #FF9B1C;
                            font-weight: bold;
                        }
                    }

                    >.task-reward {
                        flex-shrink: 0;

                        >.reward-text {
                            font-size: 9px;
                            color: #FF8707;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            // margin-right: 5px;
                            >.task-reward-icon {
                                width: 12px;
                                height: 12px;
                                .background-img-max(url('@{assetsUrlMind}/img/home/balance-icon.png'));
                            }

                            >span {
                                font-size: 9px;
                                color: #FF8707;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                >.left-content {
                    margin-left: 14px;
                    font-size: 10px;
                    line-height: 1.2em;
                    color: #8C6C52;
                    font-weight: bold;
                }
            }

            >.task-row-right {
                position: relative;
                width: 69px;
                height: 16px;
                margin-right: 15px;
                flex-shrink: 0;

                >.task-progress {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    >.task-progress-bar-s {
                        width: 74px;
                        height: 19px;
                        position: absolute;
                        left: -2px;
                        top: 0px;
                        .background-img-max(url('@{assetsUrlV2_0}/taskCenter/bar_out_s.png'));
                    }

                    >.task-progress-text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        font-size: 10px;
                        font-weight: bold;
                        color: #7CBA11;
                        transform: translate(-50%, -50%);
                        z-index: 99;

                        &.undo {
                            color: #D72E16;
                        }
                    }

                    >.task-progress-bar {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        overflow: visible;
                        border-radius: 9px;
                        overflow: hidden;
                        .background-img-max(url('@{assetsUrlV2_0}/taskCenter/bar_out.png'));

                        >.task-progress-bar-inner {
                            width: 100%;
                            height: 100%;
                            margin-left: -100%;
                            .background-img-max(url('@{assetsUrlV2_0}/taskCenter/bar_inner.png'));
                        }
                    }
                }

                >.task-reward {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -16px;
                    // height: 10px;
                    padding: 1px 6px;
                    min-height: 11px;
                    background: #FDECC6;
                    border-radius: 12px;
                    z-index: 1;

                    >.reward-text {
                        white-space: nowrap;
                        font-size: 8px;
                        color: #FF9B1C;
                        font-weight: bold;
                        line-height: 1em;
                    }

                    >.reward-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        // margin-right: 5px;
                        >.task-reward-icon {
                            flex-shrink: 0;
                            width: 10px;
                            height: 10px;
                            .background-img-max(url('@{assetsUrlMind}/img/home/balance-icon.png'));
                        }

                        >span {
                            white-space: nowrap;
                            font-size: 8px;
                            line-height: 1em;
                            color: #FF8707;
                            margin-right: 3px;
                            font-weight: bold;
                        }
                    }
                }

                >.wait-handle {
                    position: absolute;
                    width: 21px;
                    height: 17px;
                    top: -15px;
                    right: -5px;
                    z-index: 2;
                    .background-img-max(url('@{assetsUrlV2_0}/taskCenter/revise.png'));
                }
            }
        }
    }

    &.mini-scrollbar-task {

        &::-webkit-scrollbar {
            width: 14px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: linear-gradient(to right, #FEC17A, #FFCE74, #FEC17A);
        }

        &::-webkit-scrollbar-thumb::before {
            content: '';
        }

        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-image: linear-gradient(to right, #F7D9A6, #FBE8C4, #F7D9A6);
        }

        & {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
</style>