import text from '@/global/text/text'
import avatar from './avatar'
import cabin from './cabin'
import palace from './palace'

export default {
    ...text,
    'components': {
        ...text['components'],
        'pet feed confirm': '确认花费{price}思维币购买宠物零食',
    },
    'common': {
        ...text['common'],
    },
    'pet': {
        ...text['pet'],
        'purchase price notice': '重新领养宠物将花费{price}思维币',
        'claim pet again confirm': '重新领养宠物需花费{price}思维币，是否确认重新领养'

    },
    'certSingle': {
        ...text['certSingle'],
        t3: '高途思维',
        content1: '在本阶段数理思维的学习中态度认真，逻辑思维能力明显精进，经本站鉴定数理思维能力己达到',
        content2: '，特颁此证，以资证明',
    },
    'video': {
        'award': '恭喜小脑王成功完成学习任务，特奖励你思维币{value}枚',
        'challenge notice': '本次脑王挑战赛的内容你学会了吗？'
    },
    ...avatar,
    ...cabin,
    ...palace,
}